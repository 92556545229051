<template>
  <div class="toast" :class="`${type}-toast`">
    <component
      class="w-12"
      :color="colors.icon"
      :is="type === 'success' ? 'SuccessIcon' : 'ErrorWarningIcon'">
    </component>
    <div class="flex-1 pl-3.5">
      <p class=" font-semibold" :class="colors.header">
        {{ header }}
      </p>
      <p class="font-medium" :class="colors.text">
        {{ text }}
      </p>
    </div>
    <close-icon
      class="w-5 cursor-pointer"
      @click="close" />
    <div class="absolute bottom-0 left-9 right-9 h-0.75">
      <div
        class=" absolute left-0 top-0 h-full transition-all ease-linear rounded-[3px]"
        ref="time"
        :style="{
          background: colors.time,
          transitionProperty: 'width',
          transitionDuration: `${timeout / 1000}s`,
          width: timeWidth,
        }"></div>
    </div>
  </div>
</template>

<script>
import SuccessIcon from './SuccessIcon.vue';
import ErrorWarningIcon from './ErrorWarningIcon.vue';
import CloseIcon from './CloseIcon.vue';

export default {
  name: 'Toast',
  props: {
    id: Number,
    type: String,
    timeout: {
      type: Number,
      default: 7000,
    },
    header: String,
    text: String,
  },
  emits: ['close'],
  components: {
    SuccessIcon,
    CloseIcon,
    ErrorWarningIcon,
  },
  data: () => ({
    timer: null,
    timeWidth: 0,
  }),
  computed: {
    colors() {
      switch (this.type) {
        case 'success':
          return {
            header: 'text-89f',
            text: 'text-74f',
            // bg: 'rgba(28, 138, 99, 0.35)',
            icon: [''],
            time: 'linear-gradient(270deg, #93DEBF 0.56%, rgba(40, 119, 137, 0) 58.47%)',
          };
        case 'error':
          return {
            header: 'text-fec',
            text: 'text-ff7',
            // bg: 'rgba(137, 29, 68, 0.48)',
            icon: ['text-f83', 'text-fec'],
            time: 'linear-gradient(270deg, #FFD3E2 0.56%, rgba(116, 46, 86, 0) 58.47%)',
          };
        case 'warning':
          return {
            header: 'text-a0d',
            text: 'text-77c',
            // bg: 'rgba(35, 85, 132, 0.3)',
            icon: ['text-487', 'text-a0d'],
            time: 'linear-gradient(269.99deg, #6EBDD7 0.56%, rgba(30, 72, 106, 0) 57.04%)',
          };
        default:
          return {
            header: '',
            text: '',
            bg: '',
            icon: [''],
          };
      }
    },
    toastIdForDeleting() { return this.$store.state.toastIdForDeleting; },
  },
  watch: {
    toastIdForDeleting(toastIdForDeleting) {
      if (toastIdForDeleting === this.id) this.close();
    },
  },
  mounted() {
    if (this.timeout) {
      this.timer = setTimeout(() => {
        this.close();
      }, this.timeout);
      setTimeout(() => {
        this.timeWidth = '100%';
      }, 10);
    }
  },
  beforeUnmount() {
    clearTimeout(this.timer);
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="postcss" scoped>
.toast {
  @apply w-96 h-18 rounded-full flex items-center pl-3 pr-4 justify-between text-sm
    relative -ml-100 mb-3 backdrop-filter backdrop-blur-md;
  transition: top 0.5s ease;
  &.success-toast {
    background-color: rgba(28, 138, 99, 0.35);
  }
  &.error-toast {
    background-color: rgba(137, 29, 68, 0.48);
  }
  &.warning-toast {
    background-color: rgba(35, 85, 132, 0.3);
  }
}
</style>
