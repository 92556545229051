import { createStore } from 'vuex';
import panel from './panel';

export default createStore({
  state: {
    toast: null,
    toastIdForDeleting: null,
    notConnected: false,
    forceDisconnected: false,
    authToken: localStorage.getItem('authToken') || '',
    role: localStorage.getItem('role') || null,
    controllerTime: null,
  },
  getters: {
    isAdmin(state) { return state.role === 'admin'; },
  },
  mutations: {
    addToast(state, toast) { state.toast = toast; },
    deleteToast(state, toastId) { state.toastIdForDeleting = toastId; },
    changeConnectionStatus(state, { notConnected }) {
      if (notConnected || !state.forceDisconnected) state.notConnected = notConnected;
    },
    setAuthData(state, { authToken, role }) {
      state.authToken = authToken;
      state.role = role;
      localStorage.setItem('authToken', authToken);
      localStorage.setItem('role', role);
    },
    removeAuthData(state) {
      state.authToken = '';
      state.role = null;
      localStorage.removeItem('authToken');
      localStorage.removeItem('role');
    },
    forceDisconnect(state, { notConnected }) {
      state.forceDisconnected = notConnected;
      if (notConnected) state.notConnected = true;
    },
    setControllerTime(state, time) { state.controllerTime = time; },
  },
  actions: {
  },
  modules: {
    panel,
  },
});
