<template>
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      r="3.92303"
      transform="matrix(-1 0 0 1 3.92463 30.0754)"
      :fill="color || '#45D2FF'"
      class=" transition-colors duration-300"
    />
    <circle
      r="3.92303"
      transform="matrix(-1 0 0 1 16.9988 16.9972)"
      :fill="color || '#2298FF'"
      class=" transition-colors duration-300"
    />
    <circle
      r="3.92303"
      transform="matrix(-1 0 0 1 30.077 30.0754)"
      :fill="color || '#1C60E5'"
      class=" transition-colors duration-300"
    />
    <circle
      r="3.92303"
      transform="matrix(-1 0 0 1 16.9988 30.0754)"
      :fill="color || '#2298FF'"
      class=" transition-colors duration-300"
    />
    <circle
      r="3.92303"
      transform="matrix(-1 0 0 1 30.077 16.9972)"
      :fill="color || '#1C60E5'"
      class=" transition-colors duration-300"
    />
    <circle
      r="3.92303"
      transform="matrix(-1 0 0 1 3.92463 3.92303)"
      :fill="color || '#45D2FF'"
      class=" transition-colors duration-300"
    />
    <circle
      r="3.92303"
      transform="matrix(-1 0 0 1 16.9988 3.92303)"
      :fill="color || '#2298FF'"
      class=" transition-colors duration-300"
    />
    <circle
      r="3.92303"
      transform="matrix(-1 0 0 1 30.077 3.92303)"
      :fill="color || '#1C60E5'"
      class=" transition-colors duration-300"
    />
  </svg>
</template>

<script>
export default {
  name: 'LogoImage',
  props: ['isDisabled', 'isLogoError'],
  computed: {
    color() {
      if (!this.isDisabled && !this.isLogoError) return null;
      return this.isDisabled ? '#193E60' : '#F83068';
    },
  },
};
</script>
