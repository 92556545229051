<template>
  <div :class="{ nc: notConnected }" class="h-full absolute top-0 left-0 w-full">
    <router-view :class="[viewClasses]" />
    <page-header v-if="['Panel', 'Settings'].includes($route.name)" />
    <span
      v-if="isDevelopment"
      class=" fixed top-2/4 right-0 font-bold cursor-pointer z-50"
      :class="notConnected ? 'text-red-900' : 'text-white'"
      @click="$store.commit('forceDisconnect', { notConnected: !notConnected })">
      NC
    </span>
    <toasts />
    <check-controller-accessibility v-if="$route.name !== 'Login'" />
  </div>
</template>

<script>
// import axios from 'axios';
import { useI18n } from 'vue-i18n';
import '@/assets/style/styles.scss';
import 'tailwindcss/tailwind.css';
import Toasts from './components/common/toast/Toasts.vue';
import PageHeader from './components/common/header/PageHeader.vue';
import CheckControllerAccessibility from './components/common/CheckControllerAccessibility.vue';

export default {
  components: {
    Toasts,
    PageHeader,
    CheckControllerAccessibility,
  },
  data: () => ({
    toast: null,
    isDevelopment: process.env.NODE_ENV === 'development',
  }),
  computed: {
    viewClasses() {
      return this.$route.name === 'Login' ? ''
        : 'h-full bg-cover select-none overflow-hidden pb-8 relative flex flex-col pt-20';
    },
    notConnected() { return this.$store.state.notConnected; },
  },
  watch: {
    notConnected(notConnected) {
      if (this.$route.name === 'Login') return;
      if (notConnected) {
        this.toast = this.$toast.warning(
          this.t('toasts.disconnected.header'), this.t('toasts.disconnected.text'), 0,
        );
      } else if (this.toast) {
        this.$store.commit('deleteToast', this.toast);
        this.toast = null;
        this.$toast.success(this.t('toasts.connected.header'), this.t('toasts.connected.text'));
      }
    },
  },
  created() {
    // axios.interceptors.request.use((config) => {
    //   const newConfig = config;
    //   newConfig.url = window.location.host.indexOf('10.8.0.50') >= 0
    //     ? `${config.url}.php` : config.url;
    //   return newConfig;
    // }, (error) => Promise.reject(error));
    // axios.interceptors.response.use(
    //   (response) => {
    //     if (response.data.status === 'error' && response.data.code === 18) {
    //       if (this.$route.name !== 'Login') {
    //         this.$router.push({ name: 'Login' });
    //         throw new Error();
    //       }
    //     }
    //     return response;
    //   },
    //   (error) => Promise.reject(error),
    // );
  },
  mounted() {
    window.testStore = process.env.NODE_ENV === 'development' ? this.$store : null;
  },
  setup() {
    const { t } = useI18n({
      messages: {
        ru: {
          toasts: {
            disconnected: { header: 'Соединение отсутствует', text: 'Проверьте подключение' },
            connected: { header: 'Соединение восстановлено', text: 'Продолжайте работу с NG' },
          },
        },
        en: {
          toasts: {
            disconnected: { header: 'The device offline', text: 'Check connection' },
            connected: { header: 'The device online', text: 'Continue working with NG' },
          },
        },
      },
    });
    return { t };
  },
};
</script>

<style lang="scss" scoped>
.common-bg {
  background: linear-gradient(109.17deg, #10375A 0.01%, #0B2D4D 108.5%);
}
</style>
