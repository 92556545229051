<template>
  <div class="bg-092 fixed h-20 top-0 left-0 items-center px-10 grid grid-cols-12 gap-4 w-full">
    <logo-image
      class="h-1.68 col-span-1"
      :isDisabled="notConnected"
      :isLogoError="isLogoError" />
    <div class=" flex items-center col-span-3">
      <transition name="fade-300">
        <div class="flex items-center" v-if="date">
          <span
            class="text-4.2xl text-ade nc:text-3e6"
            :class="{ 'tracking-wide': hours && minutes }">
            {{ time }}
          </span>
          <div class="text-sm px-4 leading-4 flex flex-col">
            <span class="text-ade nc:text-3e6 font-semibold tracking-wide">
              {{ day }}
            </span>
            <span class="text-638 nc:text-3e6 font-medium">{{ date }}</span>
          </div>
          <signal-image class="w-9" :gsmSignal="gsmSignal" />
          <div class=" relative ml-4 h-[3.8rem] flex items-center">
            <info-icon
              class="w-4.5"
              @mouseenter="isNgInfoShown = true"
              @mouseleave="isNgInfoShown = false" />
            <transition name="fade-300">
              <div
                v-if="isNgInfoShown"
                class="absolute left-7.5 top-0 h-[3.8rem] bg-183 text-sm px-8 rounded-full
                  flex flex-col justify-center z-10">
                <div class="whitespace-nowrap">
                  <span class="font-semibold text-77c tracking-[0.01em]">
                    EVO NG ID:
                  </span>
                  <span class="font-medium text-a0d tracking-[0.03em]">
                    {{ controllerId }}
                  </span>
                </div>
                <div class="whitespace-nowrap">
                  <span
                    class="font-semibold text-77c"
                    :class="{ 'tracking-[0.05em]': locale === 'en' }">
                    {{ `${t('firmware')} ` }}
                  </span>
                  <span class="font-medium text-a0d">
                    {{ version }}
                  </span>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </transition>
    </div>
    <div class=" flex col-span-5 h-full font-semibold relative justify-start">
      <div class=" h-full relative flex mr-4 ml-[23%]" ref="menu">
        <!-- <a
          v-for="(item, page, index) in menu"
          :key="page"
          :href="oldMenu[page]"
          :ref="page"
          class=" h-full flex items-center nc:text-3e6 whitespace-nowrap"
          :class="[
            page === $route.name ? 'text-ade' : 'text-638 hover:text-5ab',
            {
              'mr-8': !index,
              'ml-8': index === 2,
            },
          ]">
          {{ item }}
        </a> -->
        <router-link
          :to="{ name: 'Panel', params: { lang: $route.params.lang } }"
          ref="Panel"
          class=" h-full flex items-center nc:text-3e6 whitespace-nowrap mr-8"
          :class="[
            'Panel' === $route.name ? 'text-ade' : 'text-638 hover:text-5ab',
          ]">
          {{ t('panel') }}
        </router-link>
        <a
          href="/function.html"
          ref="Functions"
          class=" h-full flex items-center nc:text-3e6 whitespace-nowrap text-638 hover:text-5ab">
          {{ t('functions') }}
        </a>
        <router-link
          :to="{ name: 'Settings', params: { lang: $route.params.lang, type: 'common' } }"
          ref="Settings"
          class=" h-full flex items-center nc:text-3e6 whitespace-nowrap ml-8"
          :class="[
            'Settings' === $route.name ? 'text-ade' : 'text-638 hover:text-5ab',
          ]">
          {{ t('settings') }}
        </router-link>
        <!-- <router-link
          v-for="(item, page, index) in menu"
          :key="page"
          :to="{ name: page }"
          :ref="page"
          class=" h-full flex items-center nc:text-3e6 whitespace-nowrap"
          :class="[
            page === $route.name ? 'text-ade' : 'text-638 hover:text-5ab',
            {
              'mr-8': !index,
              'ml-8': index === 2,
            },
          ]">
          {{ item }}
        </router-link> -->
        <div class=" absolute bottom-0 left-0 w-full h-0.5 bg-093 rounded-t-sm">
          <div
            class=" absolute inline-block h-full left-0 bg-158 transition-all duration-300
              rounded-t-sm line-shadow nc:opacity-0"
            :style="menuLineParams"></div>
        </div>
      </div>
    </div>
    <div
      class=" flex col-span-3 h-full font-semibold relative items-center justify-end">
      <div
        class="cursor-pointer flex items-center h-full relative group"
        ref="userBlock"
        @click="showUserSubmenu">
        <user-icon class="w-10 mr-3" />
        <span class="text-0.93 text-638 group-hover:text-ade nc:text-3e6">EVO Electronics</span>
        <transition name="fade-150">
          <div
            class="absolute top-full -mt-3 -right-6 w-64 px-6 pb-6 cursor-default"
            @click.stop
            v-show="isUserSubmenuVisible">
            <div
              class=" bg-133 p-2 text-sm font-medium text-ade w-full
                 rounded-lg user-submenu">
              <a
                v-if="menuHasProfile"
                href="/"
                class=" flex items-center px-3 h-9 rounded hover:bg-144 hover:pl-4.5
                  transition-all duration-300"
                @click.prevent>
                <user-mini-icon class="w-4.5 mr-2" />
                {{ t('profile') }}
              </a>
              <a
                href=""
                @click.prevent="logout"
                class=" flex items-center px-3 h-9 rounded hover:bg-144 hover:pl-4.5
                  transition-all duration-300">
                <signout-icon class="w-4.5 mr-2" />
                {{ t('logout') }}
              </a>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <div class=" absolute left-0 top-0 h-full w-full" v-if="notConnected"></div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
// import axios from 'axios';
import moment from 'moment';
import LogoImage from '../images/LogoImage.vue';
import SignalImage from './SignalImage.vue';
import UserIcon from './UserIcon.vue';
import UserMiniIcon from './UserMiniIcon.vue';
import SignoutIcon from './SignoutIcon.vue';
import InfoIcon from '../../views/panel/images/InfoIcon.vue';

export default {
  name: 'PageHeader',
  components: {
    LogoImage,
    SignalImage,
    UserIcon,
    UserMiniIcon,
    SignoutIcon,
    InfoIcon,
  },
  data: () => ({
    isLogoError: false,
    menuLineParams: { transform: null, width: 0 },
    isUserSubmenuVisible: false,
    isNgInfoShown: false,
    controllerId: null,
    hours: null,
    minutes: null,
    day: null,
    date: null,
    version: null,
    moment,
    gsmSignal: 5,
    isLoggingOut: false,
    timer: null,
    cancelRequest: null,
    isDestroyed: false,
    menuHasProfile: false,
  }),
  computed: {
    notConnected() { return this.$store.state.notConnected; },
    time() {
      if (this.hours && this.minutes) {
        return `${this.hours}:${this.minutes}`;
      }
      return null;
    },
    menu() {
      return {
        Panel: this.t('panel'),
        Functions: this.t('functions'),
        Settings: this.t('settings'),
      };
    },
    oldMenu() {
      const { lang } = this.$route.params;
      return {
        Panel: `/${lang}/panel`,
        Functions: '/function.html',
        Settings: `/${lang}/settings/common`,
      };
    },
    controllerTime() { return this.$store.state.controllerTime; },
    timeout() {
      return this.$route.name === 'Panel' ? 10000 : 1000;
    },
  },
  watch: {
    controllerTime(parsed) {
      if (!parsed) return;
      this.hours = this.locale === 'ru' ? parsed.format('HH') : parsed.format('hh');
      this.minutes = parsed.format('mm');
      const day = parsed.format('dddd');
      this.day = `${day.slice(0, 1).toUpperCase()}${day.slice(1)}`;
      const date = parsed.format('D MMMM').split(' ');
      this.date = this.locale === 'ru'
        ? `${date[0]} ${date[1].slice(0, 1).toUpperCase()}${date[1].slice(1)}`
        : `${date[1].slice(0, 1).toUpperCase()}${date[1].slice(1)} ${date[0]} `;
    },
    notConnected(notConnected) {
      if (notConnected) {
        clearTimeout(this.timer);
        if (this.cancelRequest) this.cancelRequest();
      } else {
        this.getInfo();
      }
    },
    locale() { setTimeout(this.setMenuLineParams, 0); },
  },
  created() {
    this.getInfo();
    // this.timer = setInterval(() => {
    //   if (!this.notConnected) this.getInfo();
    // }, 10000);
    // moment.locale(this.locale);
  },
  mounted() {
    this.$watch(
      () => this.$route.name,
      (name) => {
        if (name !== 'Login' && name) {
          setTimeout(this.setMenuLineParams, 0);
        }
      },
      {
        immediate: true,
      },
    );
  },
  beforeUnmount() {
    this.isDestroyed = true;
    clearTimeout(this.timer);
    if (this.cancelRequest) this.cancelRequest();
  },
  methods: {
    setMenuLineParams() {
      const totalWidth = this.$refs.menu.offsetWidth;
      const itemWidth = this.$refs[this.$route.name].$el.offsetWidth;
      const itemLeft = this.$refs[this.$route.name].$el.offsetLeft;
      const width = (itemWidth / totalWidth) * 100;
      this.menuLineParams = { transform: `translateX(${itemLeft}px)`, width: `${width}%` };
    },
    showUserSubmenu() {
      this.isUserSubmenuVisible = !this.isUserSubmenuVisible;
      const { userBlock } = this.$refs;
      userBlock.removeEventListener('mouseleave', this.userSubmenuListener);
      if (this.isUserSubmenuVisible) {
        userBlock.addEventListener('mouseleave', this.userSubmenuListener, { once: true });
      }
    },
    userSubmenuListener() {
      this.isUserSubmenuVisible = false;
    },
    getInfo() {
      const cancelToken = new this.$axios.CancelToken((cancelRequest) => {
        this.cancelRequest = cancelRequest;
      });
      this.$axios.post('info', null, { cancelToken, timeout: 5000 })
        .then((r) => {
          if (typeof r.data !== 'object' || r.data === null) return;
          if ('id' in r.data) this.controllerId = r.data.id;
          if ('ver' in r.data) this.version = r.data.ver;
          if (typeof r.data.time !== 'object' || r.data.time === null) return;
          const t = r.data.time;
          if (!('year' in t) || !('mon' in t) || !('day' in t) || !('hour' in t) || !('min' in t)) {
            return;
          }
          this.gsmSignal = r.data.gsm.level;
          const formattedTime = `${t.year}-${t.mon}-${t.day} ${t.hour}:${t.min}`;
          const parsed = this.moment(formattedTime, 'YYYY-M-D H:m');
          if (!parsed.isValid()) return;
          this.$store.commit('setControllerTime', parsed);
        })
        .catch((e) => {
          if (this.$axios.isCancel(e) || e.code === 'ECONNABORTED') return;
          if (this.$route.name === 'Panel') return;
          if (!e.response && e.request) {
            this.$store.commit('changeConnectionStatus', { notConnected: true });
          }
        })
        .finally(() => {
          // this.cancelRequest = null;
          if (!this.isDestroyed && !this.notConnected) {
            this.timer = setTimeout(this.getInfo, this.timeout);
          }
        });
    },
    logout() {
      if (this.isLoggingOut) return;
      this.isLoggingOut = true;
      this.$axios.post('logout')
        .then((r) => {
          if (r.data.status === 'ok') {
            this.$store.commit('removeAuthData');
            this.$router.push({ name: 'Login' });
          }
        })
        .finally(() => {
          this.isLoggingOut = false;
        });
    },
  },
  setup() {
    const { t, locale } = useI18n({
      messages: {
        ru: {
          firmware: 'Прошивка:',
          profile: 'Профиль',
          logout: 'Выйти',
          panel: 'Панель управления',
          functions: 'Функции',
          settings: 'Настройки',
        },
        en: {
          firmware: 'Firmware:',
          profile: 'My account',
          logout: 'Log out',
          panel: 'Control panel',
          functions: 'Functions',
          settings: 'Settings',
        },
      },
    });
    return { t, locale };
  },
};
</script>

<style lang="postcss" scoped>
.line-shadow {
  box-shadow: 0px 0px 3px #158EF8;
}
.user-submenu {
  box-shadow: 0px 0px 11px #0F2A4B;
}
</style>
