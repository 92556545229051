<template>
  <!-- eslint-disable max-len -->
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      opacity="0.3"
      x="11.25"
      y="8.25"
      width="1.5"
      height="9"
      rx="0.75"
      transform="rotate(90 11.25 8.25)"
      class=" fill-current text-ade"
    />
    <path
      opacity="0.3"
      d="M7.50455 5.25C7.50455 5.66421 7.16891 6 6.75488 6C6.34085 6 6.00521 5.66421 6.00521 5.25V4.5C6.00521 2.84315 7.34777 1.5 9.0039 1.5H13.5013C15.1574 1.5 16.5 2.84315 16.5 4.5V13.5C16.5 15.1569 15.1574 16.5 13.5013 16.5H8.9987C7.34256 16.5 6 15.1569 6 13.5V12.75C6 12.3358 6.33564 12 6.74967 12C7.16371 12 7.49935 12.3358 7.49935 12.75V13.5C7.49935 14.3284 8.17063 15 8.9987 15H13.5013C14.3294 15 15.0007 14.3284 15.0007 13.5V4.5C15.0007 3.67157 14.3294 3 13.5013 3H9.0039C8.17584 3 7.50455 3.67157 7.50455 4.5V5.25Z"
      class=" fill-current text-ade"
    />
    <path
      d="M9.21967 7.28033C8.92678 6.98744 8.92678 6.51256 9.21967 6.21967C9.51256 5.92678 9.98744 5.92678 10.2803 6.21967L12.5303 8.46967C12.8232 8.76256 12.8232 9.23744 12.5303 9.53033L10.2803 11.7803C9.98744 12.0732 9.51256 12.0732 9.21967 11.7803C8.92678 11.4874 8.92678 11.0126 9.21967 10.7197L10.9393 9L9.21967 7.28033Z"
      class=" fill-current text-ade"
    />
  </svg>
</template>

<script>
export default {
  name: 'SignoutIcon',
};
</script>

<style lang="postcss" scoped>
a:hover svg path {
  @apply text-0cc;
}
</style>
