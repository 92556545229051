<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="4.69531"
      y="14.125"
      width="13.3333"
      height="1.66667"
      rx="0.833333"
      transform="rotate(-45 4.69531 14.125)"
    />
    <rect
      x="5.875"
      y="4.69531"
      width="13.3333"
      height="1.66667"
      rx="0.833333"
      transform="rotate(45 5.875 4.69531)"
    />
  </svg>
</template>

<script>
export default {
  name: 'CloseIcon',
};
</script>

<style lang="postcss" scoped>
svg rect {
  @apply fill-current transition-colors duration-300;
}
.success-toast svg {
  & rect {
    @apply text-74f;
  }
  &:hover rect {
    @apply text-89f;
  }
  &:active rect {
    @apply text-37c;
  }
}
.error-toast svg {
  & rect {
    @apply text-ff7;
  }
  &:hover rect {
    @apply text-fec;
  }
  &:active rect {
    @apply text-ff4;
  }
}
.warning-toast svg {
  & rect {
    @apply text-77c;
  }
  &:hover rect {
    @apply text-a0d;
  }
  &:active rect {
    @apply text-2fa;
  }
}
</style>
