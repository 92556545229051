<template>
  <!-- eslint-disable max-len -->
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="48" height="48" rx="24" fill="#13B78F" fill-opacity="0.87" />
    <path
      d="M29.738 18.3523C30.0957 17.9315 30.7268 17.8804 31.1476 18.238C31.5684 18.5957 31.6196 19.2268 31.2619 19.6476L22.7619 29.6476C22.3986 30.075 21.7549 30.1201 21.3356 29.7474L16.8356 25.7474C16.4228 25.3805 16.3857 24.7484 16.7526 24.3356C17.1195 23.9228 17.7516 23.8857 18.1643 24.2526L21.9002 27.5733L29.738 18.3523Z"
      fill="#B9FFDD"
    />
  </svg>
</template>

<script>
export default {
  name: 'SuccessIcon',
};
</script>
