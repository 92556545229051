<template>
  <!-- eslint-disable max-len -->
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="group"
  >
    <path
      opacity="0.3"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.5 19.25C15.3325 19.25 19.25 15.3325 19.25 10.5C19.25 5.66751 15.3325 1.75 10.5 1.75C5.66751 1.75 1.75 5.66751 1.75 10.5C1.75 15.3325 5.66751 19.25 10.5 19.25Z"
      class="fill-current text-1d5 group-hover:text-57b nc:text-3e6"
    />
    <rect
      x="9.625"
      y="8.75"
      width="1.75"
      height="6.125"
      rx="0.875"
      class="fill-current text-1d5 group-hover:text-57b nc:text-3e6"
    />
    <rect
      x="9.625"
      y="6.125"
      width="1.75"
      height="1.75"
      rx="0.875"
      class="fill-current text-1d5 group-hover:text-57b nc:text-3e6"
    />
  </svg>
</template>

<script>
export default {
  name: 'InfoIcon',
};
</script>
