<template>
  <!-- eslint-disable max-len -->
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="48" height="48" rx="24" :class="`fill-current ${color[0]}`" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24.0013 32.3346C19.3989 32.3346 15.668 28.6037 15.668 24.0013C15.668 19.3989 19.3989 15.668 24.0013 15.668C26.2959 15.668 28.3739 16.5954 29.8809 18.0959C29.5546 17.7832 29.0366 17.7875 28.7155 18.1086L28.0892 18.7349C26.9605 17.858 25.5425 17.3359 24.0026 17.3359C20.3207 17.3359 17.3359 20.3207 17.3359 24.0026C17.3359 25.5425 17.858 26.9605 18.7349 28.0892L18.1089 28.7152C17.7834 29.0406 17.7834 29.5683 18.1089 29.8937C18.4343 30.2192 18.9619 30.2192 19.2874 29.8937L19.9131 29.268C21.0422 30.1462 22.4613 30.6693 24.0026 30.6693C27.6845 30.6693 30.6693 27.6845 30.6693 24.0026C30.6693 22.4613 30.1462 21.0422 29.268 19.9131L29.894 19.2871C30.2151 18.966 30.2194 18.448 29.9067 18.1216C31.4072 19.6286 32.3346 21.7067 32.3346 24.0013C32.3346 28.6037 28.6037 32.3346 24.0013 32.3346ZM29.268 19.9131C28.9257 19.473 28.5294 19.077 28.0892 18.7349L18.7349 28.0892C19.077 28.5294 19.473 28.9257 19.9131 29.268L29.268 19.9131ZM29.9067 18.1216C29.8981 18.113 29.8896 18.1044 29.8809 18.0959C29.8853 18.1 29.8897 18.1043 29.894 18.1086C29.8983 18.1129 29.9025 18.1173 29.9067 18.1216Z"
      :class="`fill-current ${color[1]}`"
    />
  </svg>
</template>

<script>
export default {
  name: 'ErrorWarningIcon',
  props: ['color'],
};
</script>
