<template>
  <!-- eslint-disable max-len -->
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="40"
      height="40"
    >
      <rect width="40" height="40" rx="8" fill="#F3F7F9" />
    </mask>
    <g mask="url(#mask0)">
      <rect
        opacity="0.9"
        width="40"
        height="40"
        rx="8"
        fill="#194468"
        fill-opacity="0.72"
      />
      <path
        opacity="0.4"
        d="M20.0013 19.1667C18.1604 19.1667 16.668 17.6743 16.668 15.8333C16.668 13.9924 18.1604 12.5 20.0013 12.5C21.8423 12.5 23.3346 13.9924 23.3346 15.8333C23.3346 17.6743 21.8423 19.1667 20.0013 19.1667Z"
        fill="#45D2FF"
        fill-opacity="0.72"
      />
      <path
        d="M12.5005 26.8314C12.8235 22.8541 16.0516 20.832 19.9861 20.832C23.976 20.832 27.2541 22.743 27.4983 26.832C27.508 26.9949 27.4983 27.4987 26.8722 27.4987C23.7843 27.4987 19.1956 27.4987 13.1063 27.4987C12.8973 27.4987 12.4829 27.048 12.5005 26.8314Z"
        fill="#45D2FF"
        fill-opacity="0.72"
      />
      <rect
        width="40"
        height="40"
        rx="8"
        fill="#133a5a"
        class=" opacity-0 nc:opacity-70"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'UserIcon',
};
</script>
