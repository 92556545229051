<template>
  <!-- eslint-disable max-len -->
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.3"
      d="M9 8.25C7.34315 8.25 6 6.90685 6 5.25C6 3.59315 7.34315 2.25 9 2.25C10.6569 2.25 12 3.59315 12 5.25C12 6.90685 10.6569 8.25 9 8.25Z"
      class=" fill-current text-ade"
    />
    <path
      d="M2.25049 15.1494C2.54119 11.5699 5.44643 9.75 8.98751 9.75C12.5784 9.75 15.5287 11.4699 15.7484 15.15C15.7572 15.2966 15.7484 15.75 15.185 15.75C12.4058 15.75 8.27604 15.75 2.79563 15.75C2.60753 15.75 2.23465 15.3444 2.25049 15.1494Z"
      class=" fill-current text-ade"
    />
  </svg>
</template>

<script>
export default {
  name: 'UserMiniIcon',
};
</script>

<style lang="postcss" scoped>
a:hover svg path {
  @apply text-0cc;
}
</style>
