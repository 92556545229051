<template>
  <div class=" fixed top-5 right-0 w-0 h-0">
    <transition-group name="list">
    <toast
      v-for="(t, i) in toasts"
      :key="t.id"
      :id="t.id"
      :type="t.type"
      :header="t.header"
      :text="t.text"
      :timeout="t.timeout"
      @close="closeToast(i)"
      :style="i === 1 ? 'margin-top: 0;' : ''" />
    </transition-group>
  </div>
</template>

<script>
import Toast from './Toast.vue';

export default {
  name: 'Toasts',
  components: { Toast },
  data: () => ({
    toasts: [],
  }),
  computed: {
    toast() { return this.$store.state.toast; },
  },
  watch: {
    toast(toast) {
      this.toasts.push(toast);
      if (this.toasts.length > 2) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.toasts.shift();
          }, 0);
        });
      }
    },
  },
  methods: {
    closeToast(index) {
      this.toasts.splice(index, 1);
    },
  },
};
</script>
