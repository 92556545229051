import store from '../store/index';

export default {
  install: (app) => {
    // const id = () => Math.random();
    const addToast = (id, type, header, text, timeout) => {
      store.commit('addToast', {
        id,
        type,
        header,
        text,
        timeout,
      });
    };
    const $toast = {
      id: null,
      success(header, text, timeout) {
        this.id = Math.random();
        addToast(this.id, 'success', header, text, timeout);
        return this.id;
      },
      warning(header, text, timeout) {
        this.id = Math.random();
        addToast(this.id, 'warning', header, text, timeout);
        return this.id;
      },
      error(header, text, timeout) {
        this.id = Math.random();
        addToast(this.id, 'error', header, text, timeout);
        return this.id;
      },
    };
    Object.assign(app.config.globalProperties, {
      $toast: Object.create($toast),
    });
  },
};
