export default {
  namespaced: true,
  state: {
    chosenDevices: localStorage.getItem('chosenDevices')
      ? JSON.parse(localStorage.getItem('chosenDevices')) : [],
    chosenInterfaces: localStorage.getItem('chosenInterfaces')
      ? JSON.parse(localStorage.getItem('chosenInterfaces')) : [],
    // chosenDevices: [0, 1, 2],
    // chosenInterfaces: ['iadc', 'ibtn', 'itmp', 'obin', 'var'],
    states: {},
    labels: {},
    interfaces: [
      { value: 'iadc', label: { ru: 'Аналоговые входы', en: 'Analog inputs' } },
      { value: 'ibin', label: { ru: 'Дискретные входы', en: 'Digital inputs' } },
      { value: 'ibtn', label: { ru: 'iButton', en: 'iButton' } }, // ikey добавлено в этот интерфейс
      { value: 'ikey', label: { ru: 'iButton', en: 'iButton' } }, // ikey добавлено в этот интерфейс
      { value: 'itmp', label: { ru: 'Термометры', en: 'Thermometers' } },
      { value: 'obin', label: { ru: 'Дискретные выходы', en: 'Digital outputs' } },
      { value: 'odac', label: { ru: 'Аналоговые выходы', en: 'Analog outputs' } },
      { value: 'opwm', label: { ru: 'ШИМ-выходы', en: 'PWM outputs' } },
      { value: 'var', label: { ru: 'Внутренние переменные', en: 'Internal variables' } },
    ],
    devices: {},
    calibrationStates: {},
    wires: {},
    newWireAddedTrigger: null,
    stopRefresh: false,
    saveStack: [],
  },
  mutations: {
    setDevices(state, devices) {
      state.devices = devices;
      state.chosenDevices = state.chosenDevices.filter((d) => d in devices);
    },
    toggleDevice(state, device) {
      const index = state.chosenDevices.findIndex((d) => d === device);
      if (index >= 0) {
        state.chosenDevices.splice(index, 1);
      } else {
        state.chosenDevices.push(device);
      }
      localStorage.setItem('chosenDevices', JSON.stringify(state.chosenDevices));
    },
    chooseAllDevices(state, devices) {
      state.chosenDevices = [...devices];
      localStorage.setItem('chosenDevices', JSON.stringify(state.chosenDevices));
    },
    toggleInterface(state, interf) {
      const index = state.chosenInterfaces.findIndex((d) => d === interf);
      if (index >= 0) {
        state.chosenInterfaces.splice(index, 1);
        if (interf === 'ibtn') {
          const index2 = state.chosenInterfaces.findIndex((d) => d === 'ikey');
          if (index2 >= 0) state.chosenInterfaces.splice(index2, 1);
        }
      } else {
        state.chosenInterfaces.push(interf);
        if (interf === 'ibtn') state.chosenInterfaces.push('ikey');
      }
      localStorage.setItem('chosenInterfaces', JSON.stringify(state.chosenInterfaces));
    },
    chooseAllInterfaces(state, interfs) {
      state.chosenInterfaces = [...interfs];
      localStorage.setItem('chosenInterfaces', JSON.stringify(state.chosenInterfaces));
    },
    setDeviceState(state, { device, deviceState }) { state.states[device] = deviceState; },
    setLabels(state, { device, labels }) { state.labels[device] = labels; },
    setLabel(state, payload) {
      const { device, object, io } = payload;
      state.labels[device][object][io] = payload.value;
    },
    setIoState(state, payload) {
      const { device, object, io } = payload;
      state.saveStack.push({ type: 'state', ...payload });
      state.states[device][object][io] = payload.value;
    },
    setCalibrationStates(state, { device, calibrationStates }) {
      state.calibrationStates[device] = calibrationStates;
    },
    calibrate(state, { device, index, type }) {
      if (!state.calibrationStates[device]) state.calibrationStates[device] = {};
      if (!state.calibrationStates[device][index]) state.calibrationStates[device][index] = {};
      state.calibrationStates[device][index][type] = 1;
    },
    addWire(state, payload) {
      const { id, device, object } = payload;
      state.wires[device][object][payload.index] = id;
      state.labels[device][object][payload.index] = id;
      state.newWireAddedTrigger = payload.index;
    },
    deleteWire(state, { index, device, object }) {
      state.wires[device][object][index] = '0000000000000000';
      state.labels[device][object][index] = '';
    },
    cutSaveStack(state, index) { state.saveStack.splice(0, index); },
    setOneWires(state, { device, wires }) {
      state.wires[device] = wires;
    },
  },
};
