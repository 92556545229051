import axios from 'axios';

export default {
  install: (app) => {
    const $axios = axios.create({
      timeout: 5000,
      // transformRequest: [
      //   (data) => JSON.stringify(data),
      // ],
    });
    $axios.CancelToken = axios.CancelToken;
    $axios.isCancel = axios.isCancel;
    $axios.interceptors.request.use((config) => {
      const newConfig = config;
      const data = { ...config.data };
      if (config.url !== 'login') {
        data.token = app.config.globalProperties.$store.state.authToken || '';
      }
      newConfig.data = JSON.stringify(data);
      newConfig.url = window.location.host.indexOf('10.8.0.50') >= 0
        ? `/api/${config.url}.php`
        : `/api/${config.url}`;
      return newConfig;
    }, (error) => Promise.reject(error));
    $axios.interceptors.response.use(
      (response) => {
        if (response.data.status === 'error' && response.data.code === 18) {
          // console.log(app);
          if (app.config.globalProperties.$route.name !== 'Login') {
            app.config.globalProperties.$store.commit('removeAuthData');
            app.config.globalProperties.$router.push({ name: 'Login' });
            throw new Error();
          }
        }
        return response;
      },
      (error) => Promise.reject(error),
    );
    Object.assign(app.config.globalProperties, {
      $axios,
    });
  },
};
