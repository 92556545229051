import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import App from './App.vue';
import router from './router';
import store from './store';
import toast from './plugins/toast';
import axios from './plugins/axios';
import md5 from './plugins/md5';

const i18n = createI18n({ locale: 'ru', legacy: false });

Sentry.init({
  dsn: 'https://206a20e02a964327857468fa11902a7d@o726281.ingest.sentry.io/5782636',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

const app = createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .use(toast)
  .use(axios)
  .use(md5);

app.config.errorHandler = (err) => {
  Sentry.captureException(err);
};
app.mount('#app');

window.addEventListener('error', (event) => {
  Sentry.captureException(event);
});
window.addEventListener('unhandledrejection', (event) => {
  Sentry.captureException(event);
});
