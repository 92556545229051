import { createRouter, createWebHistory } from 'vue-router';
import Localization from '../Localization.vue';
import store from '../store/index';

const interfaces = store.state.panel.interfaces.map((i) => i.value).join('|');
const actions = 'calibration|scanning';

const routes = [
  {
    path: '/:lang(en|ru)',
    name: 'Localization',
    component: Localization,
    children: [
      {
        path: '',
        redirect: { name: 'Login' },
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('../views/Login.vue'),
        meta: {
          title: 'EVO controls | Login',
        },
        // beforeEnter() {
        //   if (store.state.isUserAuth) return false;
        //   return true;
        // },
      },
      {
        path: `panel/:object(${interfaces})?/:device(\\d|[12]\\d|3[0-2])?/:action(${actions})?/:run(run)?`,
        name: 'Panel',
        component: () => import('../views/Panel.vue'),
        beforeEnter: (to) => {
          if (to.params.object && !to.params.device.length) {
            return { name: 'Panel', params: { lang: to.params.lang } };
          }
          return true;
        },
        meta: {
          title: 'EVO controls | Control panel',
        },
      },
      {
        path: 'functions',
        name: 'Functions',
        component: () => import('../views/Panel.vue'),
        meta: {
          title: 'EVO controls | Functions',
        },
      },
      // {
      //   path: 'settings',
      //   redirect: { name: 'Settings', params: { type: 'common' } },
      // },
      {
        path: 'settings/:type(common|users)?',
        name: 'Settings',
        component: () => import('../views/Settings.vue'),
        beforeEnter: (to) => {
          if (!to.params.type) {
            return { name: 'Settings', params: { lang: to.params.lang, type: 'common' } };
          }
          return true;
        },
        meta: {
          title: 'EVO controls | Settings',
        },
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'Login', params: { lang: localStorage.lang || 'ru' } },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta?.title || 'EVO controls';
  next();
});

export default router;
