<template>
  <div></div>
</template>

<script>
/* eslint-disable new-cap */
// import axios from 'axios';

export default {
  name: 'CheckControllerAccessibility',
  data: () => ({
    timer: null,
    cancelRequest: null,
    isDestroyed: false,
  }),
  computed: {
    notConnected() { return this.$store.state.notConnected; },
  },
  watch: {
    notConnected(notConnected) {
      if (notConnected) {
        this.pingController();
      } else {
        clearTimeout(this.timer);
        if (this.cancelRequest) this.cancelRequest();
      }
    },
  },
  beforeUnmount() {
    this.isDestroyed = true;
    clearTimeout(this.timer);
    if (this.cancelRequest) this.cancelRequest();
  },
  methods: {
    pingController() {
      const url = 'get_time';
      const cancelToken = new this.$axios.CancelToken((cancelRequest) => {
        this.cancelRequest = cancelRequest;
      });
      this.$axios.post(url, null, { cancelToken, timeout: 5000 })
        .then(() => {
          this.$store.commit('changeConnectionStatus', { notConnected: false });
        })
        .catch(() => {})
        .finally(() => {
          if (!this.notConnected || this.isDestroyed) return;
          this.timer = setTimeout(() => {
            this.timer = null;
            this.pingController();
          }, 2000);
        });
    },
  },
};
</script>
