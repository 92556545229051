<template>
  <!-- eslint-disable max-len -->
  <svg
    width="36"
    height="25"
    viewBox="0 0 36 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 20.8667C0 18.7312 1.73117 17 3.86667 17C3.9403 17 4 17.0597 4 17.1333V24C4 24.5523 3.55228 25 3 25H0.266667C0.119391 25 0 24.8806 0 24.7333V20.8667Z"
      :class="`fill-current ${colors[0]}`"
    />
    <path
      d="M8 15.9592C8 13.7726 9.77259 12 11.9592 12C11.9817 12 12 12.0183 12 12.0408V24C12 24.5523 11.5523 25 11 25H8.13265C8.05939 25 8 24.9406 8 24.8673V15.9592Z"
      :class="`fill-current ${colors[1]}`"
    />
    <path
      d="M16 11.96C16 9.77295 17.773 8 19.96 8C19.9821 8 20 8.01791 20 8.04V24C20 24.5523 19.5523 25 19 25H16.17C16.0761 25 16 24.9239 16 24.83V11.96Z"
      :class="`fill-current ${colors[2]}`"
    />
    <path
      d="M24 7.96721C24 5.77618 25.7762 4 27.9672 4C27.9853 4 28 4.01468 28 4.03279V24C28 24.5523 27.5523 25 27 25H24.1721C24.0771 25 24 24.9229 24 24.8279V7.96721Z"
      :class="`fill-current ${colors[3]}`"
    />
    <path
      d="M32 3.96079C32 1.7733 33.7733 0 35.9608 0C35.9824 0 36 0.0175575 36 0.0392157V24C36 24.5523 35.5523 25 35 25H32.2451C32.1097 25 32 24.8903 32 24.7549V3.96079Z"
      :class="`fill-current ${colors[4]}`"
    />
  </svg>
</template>

<script>
export default {
  name: 'SignalImage',
  props: ['gsmSignal'],
  computed: {
    colors() {
      const colors = new Array(5);
      const signal = this.gsmSignal;
      if (signal >= 4) {
        colors.fill('text-158');
        if (signal === 4) colors[4] = 'text-0d4c';
      } else if (signal === 3) {
        colors.fill('text-d89', 0, 3);
        colors.fill('text-635', 3, 5);
      } else if (signal > 0) {
        colors.fill('text-f83', 0, signal);
        colors.fill('text-902', signal, 5);
      } else {
        colors.fill('text-1c4');
      }
      return colors.map((c) => `${c} nc:text-1c4`);
    },
  },
  mounted() {
    // this.timer = setInterval(() => {
    //   let random = Math.round(Math.random() * 2) - 1;
    //   if (this.gsmSignal === 5 && random === 1) random = Math.random() > 0.5 ? 0 : -1;
    //   if (this.gsmSignal === 0 && random === -1) random = Math.random() > 0.5 ? 0 : 1;
    //   this.gsmSignal += random;
    // }, 1000);
  },
  // beforeMount() {
  //   clearInterval(this.timer);
  // },
};
</script>

<style lang="postcss" scoped>
svg path {
  @apply transition-colors duration-[1s];
}
</style>
