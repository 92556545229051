<template>
  <router-view />
</template>
<script>
import { useI18n } from 'vue-i18n';
import moment from 'moment';

export default {
  setup() {
    const { locale } = useI18n();
    return { locale };
  },
  beforeCreate() {
    const { lang } = this.$route.params;
    this.locale = lang;
    localStorage.setItem('lang', lang);
    moment.locale(lang);
  },
  beforeRouteUpdate(to) {
    const { lang } = to.params;
    this.locale = lang;
    localStorage.setItem('lang', lang);
    moment.locale(lang);
  },
};
</script>
